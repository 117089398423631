import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";

// 3RD-PARTY STYLES
import "react-toastify/dist/ReactToastify.css";
import "react-h5-audio-player/lib/styles.css";
import "react-tippy/dist/tippy.css";
import "react-range-slider-input/dist/style.css";

// HOOKS

// COMPONENTS
import Header from "../components/general/Header";
// import Footer from "../components/general/Footer";
import ScrollToTop from "../components/general/ScrollToTop";

// CHANGE [bscTestnet] with the chain name you'll deploy on, visit https://wagmi.sh/core/chains#supported-chains to select it from the list

function Layout() {
  return (
    <>
      <Header />
      <ScrollToTop />
      <Outlet />

      <ToastContainer
        position="top-center"
        autoClose={1500}
        transition={Flip}
      />
    </>
  );
}

export default Layout;
